<template>
    <div>
        <ul class="status_ul flex">
            <li :class="examine_status.title == item.title ? 'active' : ''" v-for="(item, index) in statusUl" :key="index"
                @click="statusShow(item)">
                {{ item.title }}
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: '',
    created() { },
    mounted() { },
    data() {
        return {
            statusUl: [{
                id: '',
                title: '全部'
            }, {
                id: 0,
                title: '待审核'
            }, {
                id: 1,
                title: '已审核'
            }, {
                id: 2,
                title: '被退回',
            },
            {
                id: 4,
                title: '暂停中',
            }],
            examine_status: {
                id: '',
                title: '全部'
            },
        }
    },
    methods: {
        statusShow(item) {
            this.examine_status = item
            this.$emit('statusTab',item.id)
        }
    },
}
</script>